// libraries
import React, { useEffect } from 'react';
import 'normalize.css';
import { graphql } from 'gatsby';
// components
import { SEO } from 'components/SEO';
import { GlobalGridVars } from 'components/UI/Grid';
import { GlobalStyles } from 'components/UI/GlobalStyles';
import { Layout } from 'components/Layout';
import { Document } from 'components/layouts/Document';
// helpers
import { openCookieInformation } from 'helpers/cookie';

const DocumentPage = ({ data }) => {
    useEffect(() => {
        const buttons = document.querySelectorAll('.cookieButtonRenew');
        buttons.forEach((button) => {
            button.addEventListener('click', openCookieInformation);
        });

        return () => {
            buttons.forEach((button) => {
                button.removeEventListener('click', openCookieInformation);
            });
        };
    }, []);

    return (
        <Layout data={data.page}>
            <GlobalStyles />
            <GlobalGridVars />
            <Document data={data.page} />
        </Layout>
    );
};
export default DocumentPage;

export const Head = ({ data }) => {
    return <SEO data={data.page.seo} />;
};

export const query = graphql`
    query ($id: String!) {
        page: wpPage(id: { eq: $id }) {
            databaseId
            title
            content
            link
            seo {
                canonical
                metaDesc
                metaRobotsNofollow
                metaRobotsNoindex
                title
            }
        }
    }
`;
